<template>
  <ul class="main-menu">
    <slot>
      <MenuItem
        v-for="(item, key) of displayedItems"
        v-bind="item"
        :active="active === key"
        :key="key"
        @click="$emit('change', key)"
      />
    </slot>
  </ul>
</template>

<script>
import px from 'vue-types'

import MenuItem from './MenuItem.vue'

export default {
  name: 'MainMenu',
  components: { MenuItem },
  model: {
    event: 'change',
    prop: 'active',
  },
  props: {
    active: px.string,
    items: px.objectOf(
      px.shape({
        tag: px.string,
        label: px.string.isRequired,
        disabled: px.bool.def(false),
        visible: px.bool.def(true),
        notice: px.string,
      })
    ),
  },
  computed: {
    displayedItems() {
      return Object.fromEntries(Object.entries(this.items).filter(([, { visible = true }]) => visible))
    },
  },
}
</script>

<style lang="scss" scoped>
.main-menu {
  list-style: none;
  padding: 0;
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
}
</style>
