<template>
  <div class="bug-report">
    <div class="bug-report-modal">
      <span class="bug-report-modal-title">Report an issue</span>
      <input
        class="form-control"
        type="email"
        id="email"
        name="email"
        placeholder="Type your email"
        required
        v-model="email"
        :disabled="loading"
      />

      <br />

      <textarea
        id="bug-description"
        name="bug-description"
        class="form-control"
        placeholder="Tell us what happened"
        v-model="message"
        :disabled="loading"
        rows="6"
        maxlength="450"
      ></textarea>
      <span class="counter">{{ message.length || 0 }}/450</span>

      <br />

      <div class="bug-report-modal-buttons">
        <button type="button" class="btn btn-secondary" @click="close" :disabled="loading">Close</button>
        <button type="button" class="btn btn-primary" @click="sendReport" :disabled="loading">
          <span v-if="loading">Sending...</span>
          <span v-else>Send</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import * as Sentry from '@sentry/vue'

import { reportIssue } from '@/utils/ow-sentry'

const EVENTS_LOG_FILE_NAME = 'events.log'
const INFO_FILE_NAME = 'info.json'

export default {
  name: 'ReportIssueForm',
  props: {
    scrimUrl: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      email: '',
      message: '',
    }
  },
  computed: {
    isOverwolfWindow() {
      return window.overwolf
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async sendReport() {
      if (!this.isOverwolfWindow) {
        close()
        return
      }

      this.loading = true
      try {
        const attachments = []

        const logsPath = await new Promise((resolve, reject) => {
          window.overwolf.utils.createLogsZip(result => {
            if (result.success) {
              resolve(result.zipPath)
            } else {
              reject(result)
            }
          })
        })
        const logsData = await new Promise((resolve, reject) => {
          window.overwolf.io.readBinaryFile(logsPath, {}, result => {
            if (result.success) {
              resolve(new Uint8Array(result.content))
            } else {
              reject(result)
            }
          })
        })
        attachments.push({
          name: 'ow-logs.zip',
          data: logsData,
          contentType: 'application/zip',
        })

        const scrimUrl = window.overwolf.windows?.getMainWindow()?.scrimUrl
        if (scrimUrl) {
          const infoFileResponse = await fetch(new URL(INFO_FILE_NAME, scrimUrl))
          if (infoFileResponse.ok) {
            attachments.push({
              name: INFO_FILE_NAME,
              data: new Uint8Array(await infoFileResponse.arrayBuffer()),
              contentType: 'application/json',
            })
          }

          const eventsLogFileResponse = await fetch(new URL(EVENTS_LOG_FILE_NAME, scrimUrl))
          if (eventsLogFileResponse.ok) {
            attachments.push({
              name: EVENTS_LOG_FILE_NAME,
              data: new Uint8Array(await eventsLogFileResponse.arrayBuffer()),
              contentType: 'text/plain',
            })
          }
        }

        reportIssue(attachments, this.email, this.message)
      } catch (error) {
        console.error(error)
        Sentry.captureException(error)
      } finally {
        this.loading = false
        this.close()
      }
    },
  },
}
</script>

<style scoped lang="scss">
.bug-report {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background-color: rgb(0 0 0 / 80%);
  z-index: 1000;
  padding-top: 60px;

  &-modal {
    background-color: #2c3034;
    width: 400px;
    min-width: 400px;
    max-width: 400px;
    margin: auto;
    display: flex;
    flex-flow: column nowrap;
    padding: 0.2rem 1rem 1rem;

    &-title {
      color: #fff;
      font-size: 1.2rem;
      text-align: center;
      margin-bottom: 1.5rem;
      margin-top: 0.5rem;
    }

    &-buttons {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      margin-top: 1rem;

      button:last-child {
        width: 120px;
      }
    }

    .counter {
      text-align: right;
      color: lightgray;
    }
  }
}
</style>
