<template>
  <AppWindow :title="title">
    <template #actionButtons>
      <button class="header-button btn btn-sm" @click="showReportIssue = true" title="Report an issue">
        <BIconBug />
      </button>
    </template>
    <template #content>
      <v3dafi />
      <ReportIssueForm v-if="showReportIssue" @close="showReportIssue = false" />
    </template>
  </AppWindow>
</template>

<script>
import { BIconBug } from 'bootstrap-vue'

import AppWindow from '@/components/overwolf/AppWindow.vue'
import ReportIssueForm from '@/components/overwolf/ReportIssueForm.vue'

import v3dafi from './v3dafi.vue'

export default {
  name: 'OwLayout',
  components: { AppWindow, v3dafi, BIconBug, ReportIssueForm },
  data() {
    return {
      showReportIssue: false,
    }
  },
  computed: {
    title() {
      return this.$route.meta.title || ''
    },
  },
}
</script>

<style lang="scss" scoped>
#app {
  min-width: $app-min-content-width;

  // width: 100vw;
  max-height: 100%;
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  position: relative;

  .scroll-root {
    overflow: auto;
  }

  .app-nav {
    @include scrollbars(5px, $sc-3, $gray-2);

    scrollbar-gutter: stable;
    max-width: $app-nav-width;
    width: 100%;
    border-right: 1px solid #202125;
    transition: max-width 0.15s;
  }

  .app-content {
    padding: 1rem !important;
    max-width: calc(100% - #{$app-nav-width});
    width: 100%;
    min-width: $min-content-width;
    transition: max-width 0.15s;
    display: flex;
    flex-flow: column nowrap;

    &.page-Map {
      padding: 0 !important;
      overflow-y: hidden;
    }
  }

  &.navbar-collapsed {
    .app-nav {
      max-width: $app-nav-collapsed-width;
    }

    .app-content {
      max-width: calc(100% - #{$app-nav-collapsed-width});
      min-width: $min-content-collapsed-width;
    }
  }
}
</style>
