import { render, staticRenderFns } from "./ServerNotices.vue?vue&type=template&id=307b3cc4&scoped=true&"
import script from "./ServerNotices.vue?vue&type=script&lang=js&"
export * from "./ServerNotices.vue?vue&type=script&lang=js&"
import style0 from "./ServerNotices.vue?vue&type=style&index=0&id=307b3cc4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "307b3cc4",
  null
  
)

export default component.exports