<template>
  <div class="app-window">
    <WindowHeader v-if="isOverwolfWindow" :title="title">
      <template #actionButtons>
        <slot name="actionButtons" />
      </template>
    </WindowHeader>
    <div class="app-content">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
import WindowHeader from './WindowHeader.vue'

export default {
  name: 'AppWindow',
  components: { WindowHeader },
  props: {
    title: {
      type: String,
      required: false,
      default: 'Scrims',
    },
  },
  computed: {
    isOverwolfWindow() {
      return window.overwolf
    },
  },
}
</script>

<style scoped>
.app-window {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
}

.app-content {
  flex: 1 1 auto;
  min-height: 0;
  height: 100%;
}
</style>
