<template>
  <header id="header" class="app-header">
    <img src="../../assets/overwolf/header_icon.svg" />
    <h1>{{ title }}</h1>
    <div class="window-controls-group">
      <slot name="actionButtons" />
      <button id="minimizeButton" class="window-control window-control-minimize btn btn-sm" />
      <button id="maximizeButton" class="window-control window-control-maximize btn btn-sm" />
      <button id="closeButton" class="window-control window-control-close btn btn-sm" />
    </div>
  </header>
</template>

<script lang="ts">
import { OWWindow } from '@overwolf/overwolf-api-ts'

export default {
  name: 'WindowHeader',
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      mainWindow: new OWWindow('background'),
      currentWindow: new OWWindow('desktop'),
      maximized: false,
    }
  },
  methods: {
    async getWindowState() {
      return await this.currentWindow.getWindowState()
    },
    setDrag: function (elem) {
      this.currentWindow.dragMove(elem)
    },
  },
  mounted() {
    const closeButton = document.getElementById('closeButton')
    const maximizeButton = document.getElementById('maximizeButton')
    const minimizeButton = document.getElementById('minimizeButton')
    const header = document.getElementById('header')

    global.overwolf.windows.getCurrentWindow(result => {
      this.currentWindow = new OWWindow(result.window.name)
      this.setDrag(header)
    })

    closeButton.addEventListener('click', () => {
      this.currentWindow.close()
    })

    minimizeButton.addEventListener('click', () => {
      this.currentWindow.minimize()
    })

    maximizeButton.addEventListener('click', () => {
      if (!this.maximized) {
        this.currentWindow.maximize()
      } else {
        this.currentWindow.restore()
      }

      this.maximized = !this.maximized
    })
  },
}
</script>
<style scoped>
.toggle-icons svg {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.toggle-icons svg:nth-of-type(1) {
  opacity: 1;
}

.toggle-icons svg:nth-of-type(2) {
  opacity: 0;
}

.toggle-icons.toggled svg:nth-of-type(1) {
  opacity: 0;
}

.toggle-icons.toggled svg:nth-of-type(2) {
  opacity: 1;
}

.app-header {
  display: flex;
  align-items: center;
  z-index: 9999;
  background-color: #272727;
  height: 40px;
}

.app-header img {
  height: 1.5rem;
}

.app-header h1 {
  font-style: normal;
  font-weight: normal;
  color: #8d8d8d;
  font-size: 14px;
  padding-left: 8px;
  margin: 0;
  cursor: inherit;
}

.app-header .hotkey-text {
  margin: 0 auto;
}

.app-header .hotkey-text kbd {
  color: #fff;
  font-weight: bold;
}

.app-header.draggable {
  user-select: none;
  cursor: grab;
}

.app-header.draggable:active {
  cursor: grabbing;
}

.app-header img {
  padding-left: 10px;
  margin: 0;
}

.window-controls-group {
  display: flex;
  z-index: 1001;
  margin-left: auto;
}

::v-deep .header-button {
  color: #a8a8a8;
  margin-right: 0.2rem;
}

::v-deep .header-button:hover {
  color: #dedede;
}

::v-deep .header-button,
.window-control {
  background-color: transparent;
  transition: background 0.15s ease-in-out;
}

::v-deep .header-button:hover,
::v-deep .header-button:active,
.window-control:hover,
.window-control:active {
  background-color: #464646;
}

.window-control::before {
  display: block;
  content: '';
  width: 30px;
  height: 30px;
  mask: url('../../assets/overwolf/window_minimize.svg') center center no-repeat;
  /* stylelint-disable-next-line */
  -webkit-mask: url('../../assets/overwolf/window_minimize.svg') center center no-repeat;
  background-color: #a8a8a8;
  transition: inherit;
}

::v-deep .header-button:hover::before,
::v-deep .header-button:active::before,
.window-control:hover::before,
.window-control:active::before {
  background-color: #dedede;
}

.window-control-minimize::before {
  mask-image: url('../../assets/overwolf/window_minimize.svg');
  /* stylelint-disable-next-line */
  -webkit-mask-image: url('../../assets/overwolf/window_minimize.svg');
}

.window-control-maximize::before {
  mask-image: url('../../assets/overwolf/window_maximize.svg');
  /* stylelint-disable-next-line */
  -webkit-mask-image: url('../../assets/overwolf/window_maximize.svg');
}

.window-control-restore::before {
  mask-image: url('../../assets/overwolf/window_restore.svg');
  /* stylelint-disable-next-line */
  -webkit-mask-image: url('../../assets/overwolf/window_maximize.svg');
}

.window-control-settings::before {
  mask-image: url('../../assets/overwolf/window_settings.svg');
  /* stylelint-disable-next-line */
  -webkit-mask-image: url('../../assets/overwolf/window_settings.svg');
}

.window-control-support::before {
  mask-image: url('../../assets/overwolf/window_support.svg');
  /* stylelint-disable-next-line */
  -webkit-mask-image: url('../../assets/overwolf/window_settings.svg');
}

.window-control-close::before {
  mask-image: url('../../assets/overwolf/window_close.svg');
  /* stylelint-disable-next-line */
  -webkit-mask-image: url('../../assets/overwolf/window_close.svg');
}

.window-control-close:hover,
.window-control-close:active {
  background-color: #c21913;
}

.window-control-close:hover::before,
.window-control-close:active::before {
  background-color: #dedede;
}

.tooltip {
  visibility: hidden;
  position: absolute;
  margin-top: -3px;
  width: 200px;
  right: 0;
  background: #464646;
  padding: 30px 5px;
  line-height: 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #d5d5d5;
}

.tooltip a {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #d5d5d5;
}

.tooltip hr {
  border-color: #272727;
  border-width: 0.5px;
}

.tooltip a:visited {
  color: #d5d5d5;
}

.support:hover .tooltip {
  visibility: visible;
}

.socialIcons {
  margin-top: 20px;
  margin-right: 10px;
}

.socialIcons img {
  vertical-align: middle;
}

.socialIcons a {
  text-decoration: none;
}
</style>
