import * as Sentry from '@sentry/vue'

import { version as pkgVersion } from '@/../package.json'

const SENTRY_ENABLED = process.env.VUE_APP_ERROR_TRACING === 'true'

let overwolfUser = null

export function logException(err, context) {
  console.warn(err, context)
  if (SENTRY_ENABLED) {
    Sentry.captureException(err, context)
  }
}

function updateUser() {
  Sentry.setUser({
    ...(overwolfUser
      ? {
          ...overwolfUser,
          overwolfUsername: overwolfUser?.username || overwolfUser?.userId,
        }
      : null),
  })
}

function updateOverwolfUser() {
  window.overwolf.profile.getCurrentUser(result => {
    // @ts-ignore
    const { success, error, ...userInfo } = result
    if (!success && error !== 'Not signed in') {
      logException(new Error(error || 'Failed to get current user'), {
        extra: { ...result },
      })
      return
    }

    overwolfUser = userInfo

    updateUser()
  })
}

export function enrichSentryWithOverwolfInfo() {
  const overwolf = window.overwolf
  if (!overwolf) {
    return
  }

  overwolf.profile.onLoginStateChanged.addListener(result => {
    Sentry.addBreadcrumb({
      type: 'debug',
      category: 'overwolf',
      message: 'onLoginStateChanged',
      level: 'debug',
      data: result,
    })
    updateOverwolfUser()
  })
  updateOverwolfUser()
}

export function postInitSentry() {
  Sentry.setTags({
    overwolfVersion: window.overwolf?.version,
    pkgVersion: pkgVersion,
    // appVersion: __VERSION__ || 'unknownnnn',
  })

  import('@/utils/overwolf.js').then(({ MANIFEST, CURRENT_WINDOW }) => {
    MANIFEST.then(manifest => {
      Sentry.setTags({
        manifestVersion: manifest?.meta?.version,
      })
    })
    CURRENT_WINDOW.then(window => {
      Sentry.setTag('windowName', window.name)
    })
  })
}

export function reportIssue(attachments = [], email, message) {
  Sentry.withScope(function (scope) {
    try {
      attachments.forEach(attachment => {
        scope.addAttachment({
          filename: attachment.name,
          data: attachment.data || '',
          contentType: attachment.contentType || 'application/octet-stream',
        })
      })
      scope.setTag('BugReport', true)
      const eventId = Sentry.captureException(new Error('BugReport'))
      Sentry.captureUserFeedback({
        event_id: eventId,
        name: 'Bug Report',
        email,
        comments: message,
      })
    } catch (err) {
      console.error(err)
    }
  })
}
