<template>
  <component :is="layout" :backend-name="backendName" @backend="changeBackend" @logout="logout"></component>
</template>

<script>
import { getBackend, setBackend, setExtraBackends } from '@/axios.js'
import { redirectToSSO } from '@/utils/sso'

import OwLayout from './layouts/OwLayout.vue'
import V2val from './layouts/v2val.vue'
import V3dafi from './layouts/v3dafi.vue'
import V3proto from './layouts/v3proto.vue'

export default {
  computed: {
    backends: () => (process.env.VUE_APP_BACKEND_OPTIONS ? JSON.parse(process.env.VUE_APP_BACKEND_OPTIONS) : null),
    backendName() {
      if (!this.backends) return ''
      if (this.backends.length > 1) {
        const baseURL = getBackend()
        const option = this.backends.find(({ backend }) => backend === baseURL)
        return option ? option.label : baseURL
      } else return ''
    },
    layout() {
      const layoutName = this.$route.meta.layout || (process.env.VUE_APP_VARIANT === 'overwolf' ? 'ow' : 'v2val')
      switch (layoutName) {
        case 'v2val':
          return V2val
        case 'v3dafi':
          return V3dafi
        case 'v3proto':
          return V3proto
        case 'ow':
          return OwLayout
        default:
          throw new Error(`Unknown layout ${layoutName}. Route ${this.$route.name} (${this.$route.path})`)
      }
    },
  },

  methods: {
    changeBackend() {
      if (!this.backends) return
      const baseURL = getBackend()
      const currentIdx = this.backends.findIndex(({ backend }) => backend === baseURL) || 0
      const newBackend = this.backends[(currentIdx + 1) % this.backends.length]
      if (!newBackend) return
      if (newBackend?.backend === baseURL) return
      setBackend(newBackend.backend)
      setExtraBackends(newBackend)
      location.reload()
    },
    logout() {
      this.$store.dispatch('auth/logout').then(is_authenticated => {
        if (!is_authenticated) {
          redirectToSSO()
        }
      })
    },
  },
}
</script>
