<template>
  <div class="notices">
    <Transition v-for="(notice, index) in notices" :key="index">
      <details class="alert alert-dismissible fade show" role="alert" :class="noticeClass(notice)">
        <summary class="alert-heading">
          {{ notice.severity.toUpperCase() }}: {{ notice.title }}
          <button type="button" class="close" aria-label="Close" @click="hide(notice)">
            <span aria-hidden="true">&times;</span>
          </button>
        </summary>
        <div class="alert-body" v-html="notice.description" />
      </details>
    </Transition>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'ServerNotices',
  created() {
    this.fetch()
    this.timer = setInterval(this.fetch, 5 * 60 * 1000 /* 5 min */)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  computed: {
    ...mapGetters({
      error: 'serverNotices/error',
      notices: 'serverNotices/notices',
    }),
  },
  methods: {
    ...mapMutations({
      hide: 'serverNotices/HIDE_NOTICE',
    }),
    ...mapActions({
      fetch: 'serverNotices/fetchNotices',
    }),
    noticeClass(notice) {
      switch (notice.severity) {
        case 'critical':
          return 'alert-danger'
        default:
          return `alert-${notice.severity}`
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.alert-heading {
  font-weight: $font-weight-bold;
}

.alert-body {
  ::v-deep {
    white-space: pre-wrap;

    a {
      color: inherit;
      font-weight: bolder;
    }
  }
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
