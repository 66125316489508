<template>
  <li class="menu-item" :class="classes">
    <!-- old btn class -->
    <!-- btn btn-block btn-outline-warning -->
    <button
      :is="subactive ? 'button' : to ? 'router-link' : href ? 'a' : 'button'"
      type="button"
      class="btn btn-primary menu-btn"
      :class="classes"
      :disabled="disabled"
      @click="!disabled && $emit('click')"
      :data-tag="!this.subactive ? tag : null"
      :href="!subactive ? href : null"
      :to="!subactive ? to : null"
    >
      <slot>
        {{ label }}
        <span v-if="notice" class="badge badge-danger" :title="notice" v-b-tooltip.hover.noninteractive>!</span>
      </slot>
    </button>
  </li>
</template>

<script>
import px from 'vue-types'

export default {
  name: 'MenuItem',
  props: {
    active: Boolean,
    beta: Boolean,
    disabled: Boolean,
    href: String,
    label: String,
    notice: String,
    subactive: Boolean,
    tag: String,
    to: px.oneOfType([px.string, px.object]),
  },
  computed: {
    classes() {
      return {
        active: this.active || this.subactive,
        disabled: this.disabled,
        subactive: this.subactive,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.menu-btn {
  display: block;
  width: 100%;

  &.disabled,
  &:disabled {
    cursor: not-allowed;
    pointer-events: auto;
  }
}
</style>
