<template>
  <div id="app" :class="{ 'navbar-collapsed': navbarCollapsed }">
    <div class="scroll-root app-nav">
      <AppNavigation
        :backend-name="backendName"
        :collapsed.sync="navbarCollapsed"
        @backend="$listeners.backend"
        @logout="$listeners.logout"
      />
    </div>

    <div class="scroll-root app-content" :class="`page-${$route.name}`">
      <WorkboxUpdates />
      <ServerNotices />
      <router-view :key="$route.name" />
    </div>
  </div>
</template>

<script>
import ServerNotices from '../components/ServerNotices.vue'
import AppNavigation from '../components/UI/AppNavigation.vue'
import WorkboxUpdates from '../components/WorkboxUpdates.vue'

export default {
  name: 'V2val',
  components: { WorkboxUpdates, AppNavigation, ServerNotices },
  props: {
    backendName: String,
  },
  data: () => ({
    navbarCollapsed: false,
  }),
}
</script>

<style lang="scss" scoped>
#app {
  min-width: $app-min-content-width;

  // width: 100vw;
  max-height: 100%;
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  position: relative;

  .scroll-root {
    overflow: auto;
  }

  .app-nav {
    @include scrollbars(5px, $sc-3, $gray-2);

    scrollbar-gutter: stable;
    max-width: $app-nav-width;
    width: 100%;
    border-right: 1px solid #202125;
    transition: max-width 0.15s;
  }

  .app-content {
    padding: 1rem !important;
    max-width: calc(100% - #{$app-nav-width});
    width: 100%;
    min-width: $min-content-width;
    transition: max-width 0.15s;
    display: flex;
    flex-flow: column nowrap;

    &.page-Map {
      padding: 0 !important;
      overflow-y: hidden;
    }
  }

  &.navbar-collapsed {
    .app-nav {
      max-width: $app-nav-collapsed-width;
    }

    .app-content {
      max-width: calc(100% - #{$app-nav-collapsed-width});
      min-width: $min-content-collapsed-width;
    }
  }
}
</style>
