<template>
  <div>
    <Transition v-if="prompt">
      <div class="alert alert-dismissible fade show alert-info" role="alert">
        <div class="alert-heading">
          New version is available.
          <button type="button" class="btn btn-outline-primary" @click="activate">Activate it now</button>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
import { Workbox } from 'workbox-window'

import mixpanel from '@/mixpanel'

const events = ['installing', 'installed', 'waiting', 'activating', 'activated', 'controlling', 'redundant', 'message']

export default {
  name: 'WorkboxUpdates',
  data: () => ({
    prompt: false,
    wasWaitingBeforeRegister: false,
  }),
  watch: {
    $route() {
      this.wb?.update()
    },
  },
  mounted() {
    if ('serviceWorker' in navigator) {
      if (process.env.VUE_APP_ENABLE_SW === 'yes') {
        this.wb = new Workbox('/sw.js', { scope: '/', updateViaCache: 'none' })

        // Add an event listener to detect when the registered
        // service worker has installed but is waiting to activate.
        this.wb.addEventListener('waiting', event => {
          // Assuming the user accepted the update, set up a listener
          // that will reload the page as soon as the previously waiting
          // service worker has taken control.
          this.wb.addEventListener('controlling', () => {
            // At this point, reloading will ensure that the current
            // tab is loaded under the control of the new service worker.
            // Depending on your web app, you may want to auto-save or
            // persist transient state before triggering the reload.
            window.location.reload()
          })

          // When `event.wasWaitingBeforeRegister` is true, a previously
          // updated service worker is still waiting.
          // You may want to customize the UI prompt accordingly.
          this.wasWaitingBeforeRegister = event.wasWaitingBeforeRegister

          // Show the prompt
          this.prompt = true
        })

        this.wb.register()
        this.interval = setInterval(() => {
          this.wb.update()
        }, 5 * 60 * 1000)

        // tracing logs
        events.forEach(event => {
          this.wb.addEventListener(event, e => this.logWbEvent(event, e))
        })
      } else {
        navigator.serviceWorker.getRegistrations().then(registrations => {
          for (let registration of registrations) {
            registration.unregister()
          }
        })
      }
    }
  },
  beforeDestroy() {
    clearInterval(this.interval)
    this.wb?.removeEventListener('waiting')
    this.wb?.removeEventListener('controlling')
    events.forEach(event => {
      this.wb?.removeEventListener(event)
    })
  },
  methods: {
    activate() {
      this.wb?.messageSkipWaiting()
      console.log('[SW] force activate')
      mixpanel.track_sw_force_activate()
    },
    logWbEvent(eventName, event) {
      console.log(`[SW] ${eventName}`, event)
      mixpanel.track_sw_event(eventName, event)
    },
  },
}
</script>

<style scoped></style>
